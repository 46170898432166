import CardsSection from "../components/sections/CardsSection/CardsSection"
import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import GeneralHeader from "../components/global/GeneralHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import React from "react"
import ValuePropsSection from "../components/sections/ValuePropsSection"

const HybridTeams = ({ location }) => {

  const valueProps = [
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933621/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/I-HybridTeams/EXPORTS-Illustrations-HybridTeams-Icon1-HouseRocket-Citrus.png",
      headline:"Boost employee morale",
      subheadline:"Keep your team happy and well-fed, no matter where they’re working from."
    },
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1652235127/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Hybrid%20Teams/EXPORT-HybridTeams-2.png",
      headline:"Ensure benefits parity",
      subheadline:"Customize your ordering options so that remote employees and in-office employees feel equally valued."
    },
    {
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1652235127/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Hybrid%20Teams/EXPORT-HybridTeams-3.png",
      headline:"Order local favorites nationwide",
      subheadline:"Recruit new hires by offering world-class meal perks whether they’re remote or in-office."
    },
  ]

  const cards_top = [
    {
      title: "Individual Meals",
      description: "Employees can order—in the office or at home—with budgets and rules to control spend.",
      link: "/individual-meals/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626794994/Grubhub%20Corporate%20Website/2021/Photography%20Elements/1-WaysToOrder-Homepage/EXPORTS-Photography-WaysToOrder-Homepage-IndividualMeals.jpg",
      linkText: "Learn more"
    },
    {
      title: "Group Ordering",
      description: "Send out restaurant choices and let your team order what they want. Or, let employees add food to a shared cart. Any way you order, meals are delivered together and everyone gets something they love.",
      link:"/group-orders/",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626794995/Grubhub%20Corporate%20Website/2021/Photography%20Elements/1-WaysToOrder-Homepage/EXPORTS-Photography-WaysToOrder-Homepage-GroupOrder.jpg",
      linkText: "Learn more"
    },
    {
      title: "Catering",
      description: "Feed your next team meeting or event with catering delivered as platters or individually boxed meals.",
      link: "/catering/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231061/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Homepage/EXPORTS-CARD-Catering.jpg",
      linkText: "Learn more"
    }
  ]

  const cards = [
    {
      title: "Digital Events",
      description: "Make your digital lunch and learns, webinars and client meetings more delicious with food!",
      link:"/meals-for-digital-events/",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796618/Grubhub%20Corporate%20Website/2021/Photography%20Elements/2-OrderingOccassion-Homepage/EXPORTS-Photography-CorpOccasionsHomepage-450x220-DigitalEvents.png",
      linkText: "Learn more"
    },
    {
      title: "Corporate Perks",
      description: "Discover how Grubhub Corporate can fit into your employee benefits package.",
      link: "/perks/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-CorporatePerks.jpg",
      linkText: "Learn more"
    },
    {
      title: "Remote Employees",
      description: "Show your remote team you care with customizable meal plans from Grubhub.",
      link: "/meals-for-remote-employees/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797391/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-RemoteEmployees.jpg",
      linkText: "Learn more"
    }
  ]

  const faqs = [
    {
      header: "How do you manage a hybrid team?",
      description: `<span>It’s critical to provide benefit parity and ensure all <a href="https://corporate.grubhub.com/blog/building-a-meal-program-to-safely-feed-hybrid-teams/">hybrid employees</a> feel valued, no matter where they are working. In fact, your company culture and employee morale depend on it. </span>`
    },
    {
      header: "What is a hybrid work environment?",
      description: `A hybrid workforce includes a blend of virtual and in person work, it could have some workers fully remote, some fully in the office, and others working both at home and in the office. `
    }
  ]

  return (
    <Layout
      location={location}
      title={"Food for Hybrid Teams | Grubhub Corporate"}
      description={"Whether your team is working at home, in the office, or both we can help feed your hybrid team!"}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "ordering occasions",
        pageName: "hybrid teams",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <GeneralHeader
        headline="Hybrid Teams"
        subheadline="We can help keep your hungry team happy, whether they're in the office, at home or a mixture of both."
        textColor="var(--color-secondary)"
        background="#E7F4EE"
        ctaLink="/get-started/"
        ctaText="Get started"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1661214056/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/August%2022%20Handoff%20-%20New%20Hybrid%20Teams%20Image/Desktop-HybridTeams-Header.png"
        alt="Three females and one male employee waving in front of a laptop on a video conferencing call."
      />
      <ValuePropsSection
        headline="The most flexible ordering options to feed your hybrid team"
        subheadline="No matter where your employees sit, they can get delicious food delivered straight to their doors."
        valueProps={valueProps}
        layout="vertical"
      />
      <CardsSection headline="The most ways to order" backgroundColor="rgba(231,244,238,0.3)" cardsPerRow={3} chunkCards cards={cards_top} isSliderOnMobile/>
      <QuoteSection
        quote='"Having a corporate account has made it much easier to make sure that all employees are taken care of. They have the option to purchase individual meals or group orders and I always have someone to contact if I have a questions or concern."'
        author="Giana R"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-GoFundMe.png"
        alt="GoFundMe logo"
        position="Workplace and Employee Experience Manager at GoFundMe"
        backgroundColor="white"
      />
      <CardsSection headline="More ordering occasions and information" backgroundColor="rgba(231,244,238,0.3)" cardsPerRow={3} chunkCards cards={cards} isSliderOnMobile/>
      <CtaSection headline="See what we can do for you" backgroundColor="white"/>
      <FaqAccordionSection faqs={faqs} backgroundColor="#F9F9F9"/>
    </Layout>
  )
}

export default HybridTeams
